<template>
    <div>
    </div>
  </template>
  <script>
  export default {
    name: 'Phone',
    data() {
      return {
        
      };
    },
  
    watch: {
    },
    created() {
  
    },
    mounted() {
      
    },
    methods: {
      
    }
  }
  </script>
  
  <style scoped>
  </style>