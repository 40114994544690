<template>
  <div id="app">
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
import Pc from './components/Pc.vue';
import Phone from './components/Phone.vue';
export default {
  components: {
    Pc,
    Phone,
  },
  data() {
    return {
    };
  },
  created() {
    this.detectDeviceType();
  },
  methods: {
    detectDeviceType() {
      const userAgent = navigator.userAgent;
      const isMobile = /mobile/i.test(userAgent);

      // 根据用户代理判断设备类型
      if (isMobile) {
        this.currentComponent = 'Phone';
      } else {
        this.currentComponent = 'Pc';
      }
    },
  },
};
</script>
